import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { Link } from "gatsby"

import Hamburger from "hamburger-react"

export const Navbar = ({ pageName }) => {
  const [isOpen, setOpen] = useState(false)

  const [width, setWidth] = useState()
  console.log(width)

  const isDesktop = width >= 992

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  return (
    <>
      {isDesktop ? (
        <nav className="mainHeader__content--nav">
          <Row style={{ padding: "0 2rem" }}>
            <Col sm={2}>
              <a href="/" className="mainHeader__content--link lobster">
                Reha-Help
              </a>
            </Col>
            <Col
              sm={10}
              className="text-right align-items-center d-flex justify-content-end"
            >
              <Link to="/offer" className="mainHeader__content--link ">
                Oferta
              </Link>
              <Link to="/price" className="mainHeader__content--link ">
                Cennik
              </Link>
              <Link to="/womans" className="mainHeader__content--link ">
                Dla kobiet
              </Link>
              <Link to="/contact" className="mainHeader__content--link ">
                Kontakt
              </Link>
            </Col>
          </Row>
        </nav>
      ) : (
        <>
          <div className="mobile-nav">
            <a href="/" className="mainHeader__content--link lobster">
              Reha-Help
            </a>
            <Hamburger color="black" toggled={isOpen} toggle={setOpen} />
          </div>
          <div class={`mobile-menu ${isOpen && "active"}`}>
            <div className="d-flex flex-column m-3">
              <Link
                to="/"
                className={`mobile-menu-link ${
                  pageName === "main" && "active"
                }`}
              >
                <div class="dot" />
                Strona główna
              </Link>
              <Link
                to="/offer"
                className={`mobile-menu-link ${
                  pageName === "offer" && "active"
                }`}
              >
                <div class="dot" />
                Oferta
              </Link>
              <Link
                to="/price"
                className={`mobile-menu-link ${
                  pageName === "price" && "active"
                }`}
              >
                <div className="dot" />
                Cennik
              </Link>
              <Link
                to="/womans"
                className={`mobile-menu-link ${
                  pageName === "womans" && "active"
                }`}
              >
                <div className="dot" />
                Dla kobiet
              </Link>
              <Link
                to="/contact"
                className={`mobile-menu-link ${
                  pageName === "contact" && "active"
                }`}
              >
                <div className="dot" />
                Kontakt
              </Link>
            </div>
            <img class="mobile-menu-image" src="/images/logoMobileBlack.svg" />
          </div>
        </>
      )}
    </>
  )
}
