import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';

import "./../styles/index.scss"

const Layout = ({ children }) => {

  return (
    <>
        <main>{children}</main>
    </>
  )
}

export default Layout
